:root {
  --green: #94bc0c;
  --blue: #64bcdc;
  font-size: 16px;
}
@media (max-width: 900px) {
  :root {
    font-size: 12px;
  }
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  touch-action: pan-x pan-y;
}

html {
  box-sizing: border-box;
}

body {
  margin: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 600px) {
  body {
    margin: 1rem;
  }
}

a {
  color: #000;
  text-decoration: none;
}